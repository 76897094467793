import React from "react";
import { AppService } from '../app.service';
import { Channel } from '@models';
import subscriptionService from './player-subscription';
import { ODA } from 'ODA.bootstrap';
import { IdName, pushNotification } from 'react-tools';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export interface AppContextData {
  channels: Channel[];
  channel: Channel | null;
  event: IdName | null;
  loading: boolean;
  addressingTreeLoading: boolean;
  addressingRulesLoading: boolean;
  addressingStreamsLoading: boolean;
  setChannel: (channel: Channel) => void;
  setEvent: (event: IdName) => void;
  playEvents: (serials: string[], reloadIfPlaying: boolean) => void;
  stopEvents: (serials: string[]) => void;
}

const contextDefaultValue: AppContextData = {
  channels: [],
  channel: null,
  loading: false,
  event: null,
  addressingTreeLoading: false,
  addressingRulesLoading: false,
  addressingStreamsLoading: false,
  setChannel: () => { },
  setEvent: () => { },
  playEvents: (serials: string[], reloadIfPlaying: boolean) => { },
  stopEvents: (serials: string[]) => { }
};


export const AppContext = React.createContext<AppContextData>(contextDefaultValue);

const service = new AppService();

class AppContextProviderBase extends React.Component<
  { children: any, pushNotification: (message: string, type?: "default" | "error" | "success" | "warning" | "info" | undefined) => void },
  AppContextData
  >
{
  constructor(props: {
    children: any,
    pushNotification: (message: string, type?: "default" | "error" | "success" | "warning" | "info" | undefined) => void
  }) {
    super(props);
    this.state = {
      ...contextDefaultValue,
      setChannel: this.setChannel,
      setEvent: this.setEvent,
      stopEvents: this.stopEvents,
      playEvents: this.playEvents
    };

    subscriptionService.subscribe(streams => this.setSubscribedPlayers(streams));
  }

  componentDidUpdate(prevProps: any, prevState: AppContextData) {
    if (this.state.event &&
      this.state.channel &&
      prevState.addressingTreeLoading === true &&
      this.state.addressingTreeLoading === false
    ) {
      subscriptionService.publishEvent(this.state.channel.id, this.state.event);
    }
  }

  setSubscribedPlayers = (streams: { [key: number]: number }) => {
    if (this.state.channel && this.state.channel.devices) {
      var a = this.state.channel.devices.filter(d => d.addressingState === 0).map(d => d.streamId).sort();
      var b = Object.keys(streams)
                    .filter((s: any) => streams[s] === 0 || streams[s] === 1)
                    .map(s => +s)
                    .sort();
      
      console.debug("Addressing from new endpoint: " + a);
      console.debug("Addressing from web worker: " + b);
      console.debug("In new endpoint, but not in web worker: " + a._difference(b));
      console.debug("In web worker, but not in new endpoint: " + b._difference(a));

      this.setState({ ...this.state, addressingRulesLoading: false });
    }
  }

  setChannel = async (channel: Channel) => {
    if (this.state.channel === null || (this.state.channel.id !== channel.id)) {
      this.setState({ ...this.state, channel, addressingTreeLoading: true });
      await subscriptionService.publishChannel(channel.id, ODA.workgroupId);
      this.setState({ ...this.state, channel, addressingTreeLoading: false });
    }
  }

  setEvent = async (event: IdName) => {
    this.setState({ ...this.state, event, addressingRulesLoading: true, addressingStreamsLoading: true });

    if (this.state.channel) {
      await this.setAddressingDevices(this.state.channel.id, event.id);
      this.setState({ ...this.state, addressingStreamsLoading: false });
    }

    if (this.state.channel && this.state.addressingTreeLoading === false) {
      subscriptionService.publishEvent(this.state.channel.id, event);
    }
  }

  setAddressingDevices = async (channelId: number, eventId: number) => {
    var streams = await service.getAddressingStreams(ODA.workgroupId, channelId, eventId);
    var nStreams: any = {};
    streams.map((s: any) => nStreams[s.id] = 0);
    if (this.state.channel && this.state.channel.devices) {
      this.state.channel.devices.map(d => d.addressingState = -1);
      this.state.channel.devices.map(d => {
        if (nStreams[d.streamId] === 0) {
          (d as any).addressingState = 0;
        }
      });
    }
  }

  playEvents = async (serials: string[], reloadIfPlaying: boolean) => {
    try {
      if (this.state.event) {
        await service.playEvents(serials, this.state.event.id, reloadIfPlaying);
        this.props.pushNotification(`PLAY command successfully sent to ${serials.length} ${serials.length === 1 ? 'device' : 'devices'}`, 'success');
      } else {
        this.props.pushNotification('No event selected', 'warning');
      }
    } catch (ex) {
      this.props.pushNotification(ex.message, 'error');
    }
  }

  stopEvents = async (serials: string[]) => {
    try {
      if (this.state.event) {
        await service.stopEvents(serials, this.state.event.id);
        this.props.pushNotification(`STOP command successfully sent to ${serials.length} ${serials.length === 1 ? 'device' : 'devices'}`, 'success');
      } else {
        this.props.pushNotification('No event selected', 'warning');
      }
    } catch (ex) {
      this.props.pushNotification(ex.message, 'error');
    }
  }

  componentDidMount() {
    const fetchChannels = async () => {
      this.setState({ ...this.state, loading: true });
      const channels = await service.getData();

      this.setState({
        ...this.state,
        channels: channels.alphabeticalSort('name', 'asc'),
        loading: false
      });
    }

    fetchChannels();
  }


  render() {
    return (
      <AppContext.Provider value={this.state} > {this.props.children}</AppContext.Provider>
    )
  }
}

const actions = (dispatch: Dispatch) => ({
  pushNotification: (message: string, type?: "default" | "error" | "success" | "warning" | "info" | undefined) => dispatch(pushNotification(message, type))
})

export const AppContextProvider = connect(null, actions)(AppContextProviderBase);