import React, { useCallback, useMemo, useEffect } from 'react';

import {
  AppBar, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography, Container
} from '@material-ui/core';

import Home from '@material-ui/icons/Home';
import Left from '@material-ui/icons/KeyboardArrowLeft';

import { ChannelView } from '../channel-view/channel-view';
import { EventView } from '../event-view/event-view';
import { ODA } from '../ODA.bootstrap';
import { PlayerView } from '../player-view/player-view';
import { useCurrentRoute, useRoutingContext, usePushRoute } from './hooks';
import { AppRoute, AppRoutingContextProvider } from './routing.context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: theme.palette.background.default,
      overflow: 'hidden'
    },
    container: {
      backgroundColor: theme.palette.background.default,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: '56px',
      position: 'relative',
      height: 0,
      paddingLeft: 0,
      paddingRight: 0
    },
    content: {
      flexGrow: 1,
      position: 'relative',
      height: 0
    },
    rightMenuButton: {
      marginLeft: theme.spacing(1),
    },
    leftMenuButton: {
      marignRight: theme.spacing(1)
    },
    title: {
      flexGrow: 1,
      textAlign: 'center'
    },
  })
);

const Router = () => {
  const classes = useStyles();
  const route = useCurrentRoute();
  const pushRoute = usePushRoute();

  const homeFn = useCallback(() => ODA.homeFn(), []);
  const routingContext = useRoutingContext();

  const content = useMemo(() => {
    switch (route) {
      case AppRoute.ChannelView:
        return <ChannelView />;
      case AppRoute.EventView:
        return <EventView />;
      case AppRoute.PlayerView:
        return <PlayerView />;
    }
  }, [route]);

  const handleBack = useCallback(() => {
    switch (route) {
      case AppRoute.PlayerView:
        return pushRoute(AppRoute.EventView);
      default:
        return pushRoute(AppRoute.ChannelView);
    }
  }, [route])

  return (
    <div className={classes.root}>
      <AppBar position={'fixed'}>
        <Toolbar>
          {route === AppRoute.ChannelView && <IconButton
            onClick={homeFn}
            edge="start"
            className={classes.leftMenuButton}
            color="inherit"
            aria-label="menu"
          >
            <Home fontSize={'large'} />
          </IconButton>}

          {route !== AppRoute.ChannelView && <IconButton
            onClick={handleBack}
            edge="start"
            className={classes.leftMenuButton}
            color="inherit"
            aria-label="menu"
          >
            <Left fontSize={'large'} />
          </IconButton>}
          <Typography className={classes.title} noWrap>
            {routingContext.title}
          </Typography>
          {routingContext.barButtons.map((button, index) => (
            <IconButton
              key={index}
              edge="end"
              className={classes.rightMenuButton}
              onClick={button.onClick}
              disabled={button.disabled}
            >
              {button.icon && button.icon()}
            </IconButton>
          ))}
        </Toolbar>
      </AppBar>

      <Container className={classes.container}>
        <div className={classes.content}>
          {content}
        </div>
      </Container>
    </div>
  );
};

export const AppRoutingSwitch = () => (
  <AppRoutingContextProvider>
    <Router />
  </AppRoutingContextProvider>
);
