import { createMuiTheme, Theme } from "@material-ui/core";

export const moodTheme: Theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          "&::-webkit-scrollbar": {
            width: "10px",
            cursor: "pointer",
            background: "rgba(255, 255, 255, 0.1)",
          },

          "&::webkit-scrollbar-track": {
            background: "black",
          },

          "&::-webkit-scrollbar-thumb": {
            background: "rgba(255, 255, 255, 0.3)",
            borderRadius: "5px",

            "&:hover": {
              background: "rgba(255, 255, 255, 0.3)",
            },
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
          WebkitBoxShadow: "0 0 0 0 transparent inset !important",
        },
      },
    },
  },
  palette: {
    primary: { main: "#1b1d21", dark: "#111214", light: '#2B3338' }, // Mood black
    secondary: { main: "#ce102d" }, // Mood red
  },
  typography: {},
});

export const moodThemeDark: Theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          color: 'inherit'
        },
        body: {
          color: 'inherit',
          margin: 0
        },
        "*": {
          "&::-webkit-scrollbar": {
            width: "10px",
            cursor: "pointer",
            background: "rgba(255, 255, 255, 0.1)",
          },

          "&::webkit-scrollbar-track": {
            background: "black",
          },

          "&::-webkit-scrollbar-thumb": {
            background: "rgba(255, 255, 255, 0.3)",
            borderRadius: "5px",

            "&:hover": {
              background: "rgba(255, 255, 255, 0.3)",
            },
          },
        },
      },
    },
  },
  palette: {
    type: "dark",
    primary: { main: "#1b1d21", dark: "#0F1012", light: '#2B3338' }, // Mood black
    secondary: { main: "#ce102d" }, // Mood red
  },
  typography: {},
});

export const bladeTheme: Theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          html: {
            color: 'inherit'
          },
          body: {
            color: 'inherit',
            margin: 0
          },
          "&::-webkit-scrollbar": {
            width: "10px",
            cursor: "pointer",
            background: "rgba(255, 255, 255, 0.1)",
          },

          "&::webkit-scrollbar-track": {
            background: "black",
          },

          "&::-webkit-scrollbar-thumb": {
            background: "rgba(255, 255, 255, 0.3)",
            borderRadius: "5px",

            "&:hover": {
              background: "rgba(255, 255, 255, 0.3)",
            },
          },
        },
      },
    },
  },
  palette: {
    type: "dark",
    primary: { main: "#5e1658", dark: "#232931" }, // Mood black
    secondary: { main: "#5e1658" }, // Purple
    error: { main: "#ce102d" }, // Mood red
  },
  typography: {},
});
