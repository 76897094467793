import { createStore, combineReducers } from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import {
  notificationsReducer, notificationsAreaName, NotificationsState,
  bladeAreaName, bladeReducer, BladeState,
} from "react-tools";

export interface AppState {
  [bladeAreaName]: BladeState;
  [notificationsAreaName]: NotificationsState;
}

export const createDefaultStore = () => {
  const store = createStore(
    combineReducers({
      [bladeAreaName]: bladeReducer,
      [notificationsAreaName]: notificationsReducer,
    }),
    composeWithDevTools()
  );
  return store;
};
