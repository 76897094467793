import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex'
    },
    root: {
      backgroundColor: 'transparent !important'
    },
    title: {
      flexGrow: 1,
      textAlign: 'center'
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main
    },
    searchFieldInput: {
      borderRadius: '18px',

      '& input': {
        paddingBottom: theme.spacing(1.2),
        paddingTop: theme.spacing(1.2),
      }
    },
    searchFieldIcon: {
      marginTop: `${theme.spacing(0.6)}px !important`
    }
  }),
);