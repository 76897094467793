import React from "react";
import { Store } from "redux";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";

import App from "./App";
import { createDefaultStore } from "./store";

const defaultStore = createDefaultStore();

// wrapper for ReactDOM.render() call
export const startReact = (rootNode = "root", appStore: Store = defaultStore) => {
  ReactDOM.render(
    <Provider store={appStore}>
      <App />
    </Provider>,
    document.getElementById(rootNode)
  );
};
