import React, { useCallback, useState, useEffect } from 'react';
import {
  makeStyles, Theme, createStyles, ListItem, Radio,
  ListItemText, List, ListItemIcon, Typography, Checkbox
} from '@material-ui/core';
import Right from '@material-ui/icons/KeyboardArrowRight';
import { IdName } from 'react-tools';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { setDefaults } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      overflowY: 'auto',
    },
    listItem: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    iconBtn: {
      minWidth: 'auto',
      padding: 0
    },
    radio: {
      paddingLeft: 0,
      paddingRight: theme.spacing(2)
    }
  }),
);

export interface AppListProps {
  items: IdName[];
  label?: (item: IdName) => string;
  onClick: (item: IdName) => void;
  selectionMode: 'none' | 'single' | 'multiple';
}

export const AppList = ({ items, label, onClick, selectionMode }: AppListProps) => {
  return (
    <List component="nav">
      {items.map((item: IdName) => (
        <AppListItem key={item.id} item={item} label={label} onClick={onClick} selectionMode={selectionMode} />
      ))}
    </List>
  );
}

export interface MultipleSelectListProps {
  items: IdName[];
  label?: (item: IdName) => string;
  defaultSelection?: IdName[];
  onSelect: (items: IdName[]) => void;
}

export const MultipleSelectList = ({ items, label, onSelect, defaultSelection }: MultipleSelectListProps) => {
  const [selected, setSelected] = useState<IdName[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (defaultSelection) {
      setSelected(defaultSelection);
    }
  }, [defaultSelection]);

  const onItemClicked = useCallback((item: IdName) => setSelected(previouslySelected => {
    const index = previouslySelected.findIndex(e => e.id === item.id);
    const result = [...previouslySelected];
    if (index > -1) {
      result.splice(index, 1);
    } else {
      result.push(item);
    }

    onSelect(result);
    return result;
  }), []);

  const ListItem = (props: { index: number, style: React.CSSProperties }) => {
    const item = items[props.index];
    const isSelected = selected.findIndex(e => e.id === item.id) > -1;

    return (
      <AppListItem style={props.style} item={item} label={label} onClick={onItemClicked} selectionMode={'multiple'} isChecked={isSelected} />
    );
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          className={classes.list}
          height={height}
          width={width}
          itemCount={items.length}
          itemSize={50}>
          {ListItem}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}

export interface SingleSelectListProps {
  items: IdName[];
  label?: (item: IdName) => string;
  onSelect: (items: IdName) => void;
  showRadio?: boolean;
  defaultSelected?: IdName | null;
}


export const SingleSelectList = ({ items, label, onSelect, showRadio, defaultSelected }: SingleSelectListProps) => {
  const [selected, setSelected] = useState<IdName | null | undefined>(defaultSelected);
  const classes = useStyles();

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const handleSelection = useCallback((item: IdName) => setSelected(() => {
    onSelect(item);
    return item;
  }), [])

  return (
    <List component="nav" className={classes.list}>
      {items.map((item: IdName) => (
        <AppListItem
          key={item.id}
          item={item}
          label={label}
          onClick={handleSelection}
          selectionMode={showRadio ? 'single' : 'none'}
          isChecked={selected ? selected.id === item.id : false}
        />
      ))}
    </List>
  )
}

interface AppListItemProps {
  item: IdName;
  label?: (item: IdName) => string;
  onClick: (item: IdName) => void;
  selectionMode: 'none' | 'single' | 'multiple';
  isChecked?: boolean;
  style?: React.CSSProperties;
}

const AppListItem = ({ item, label, onClick, selectionMode, isChecked, style }: AppListItemProps) => {
  const classes = useStyles();

  const onClickHandler = useCallback(() => onClick(item), [item]);

  return (
    <ListItem button color={'transparent'} onClick={onClickHandler} className={classes.listItem} style={{ ...style }}>
      {selectionMode === 'multiple' && (
        <Checkbox checked={isChecked} />
      )}

      {selectionMode === 'single' && (
        <Radio checked={isChecked} className={classes.radio} />
      )}
      <ListItemText >
        <Typography color={'textPrimary'} variant={'body1'} noWrap>
          {label ? label(item) : item.name}
        </Typography>
      </ListItemText>
      {selectionMode === 'none' &&
        <ListItemIcon className={classes.iconBtn}>
          <Right color={'secondary'} fontSize={'large'} />
        </ListItemIcon>
      }
    </ListItem>
  );
}

