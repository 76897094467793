import HttpService from './utils/http';
import { Channel, IPlayer, OnDemandAppDataResponse } from './@models';
import { normalize, denormalize, Normalized } from 'react-tools';

interface IAppService {
  getAddressingTree(channelId: number, workgroupId: number): Promise<any>;
  getAddressingRules(channelId: number, mediaId: number): Promise<any>;
  getData(): Promise<any>;
  getAddressingStreams(workgroupId: number, channelId: number, mediaId: number): Promise<any>;
}

export class AppService implements IAppService {
  async getData(): Promise<Channel[]> {
    var response = await HttpService.get<OnDemandAppDataResponse>(`v6/onDemandApp`);

    var nChannels: Normalized<Channel> = normalize(response.channels, 'id') as any;
    var nEvents = normalize(response.events, 'id');
    var nDevices = normalize(response.devices, 'id');
    var nSites = normalize(response.sites, 'id');

    response.channelsAndEvents.map((e: any) => {
      const channelOfEvent = nChannels[e.channelId];

      if (channelOfEvent.events === undefined) {
        channelOfEvent.events = [];
      }

      channelOfEvent.events.push({
        id: e.eventId,
        name: nEvents[e.eventId].name
      });
    });

    response.channelsAndDevices.map((cd: any) => {
      const channelOfDevice = nChannels[cd.channelId];

      if (channelOfDevice.devices === undefined) {
        channelOfDevice.devices = [];
      }

      channelOfDevice.devices.push({
        id: cd.deviceId,
        name: nDevices[cd.deviceId].name,
        serialNumber: nDevices[cd.deviceId].serialNumber,
        streamId: cd.streamId,
        site: {
          id: cd.siteId,
          name: nSites[cd.siteId].name
        },
        addressingState: 0
      });
    });

    return denormalize(nChannels);
  }

  async getAddressingTree(channelId: number, workgroupId: number): Promise<IPlayer[]> {
    const url = `v6/addressingStructureLight/${channelId}/${workgroupId}`;
    //const tree = await HttpService.get<IPlayer[]>(url);
    const tree: IPlayer[] = [];

    return tree;
  }

  async getAddressingRules(channelId: number, mediaId: number): Promise<any> {
    const url = `v6/addressingRules/${channelId}/${mediaId}`;
    const rules = await HttpService.get<any>(url);

    return rules;
  }

  async getAddressingStreams(workgroupId: number, channelId: number, mediaId: number): Promise<any> {
    const url = `v6/addressingStructure/onDemand/${workgroupId}/${channelId}/${mediaId}`;
    const streams = await HttpService.get<any>(url);

    return streams;
  }

  async playEvents(serialNumbers: string[], mediaId: number, reloadIfPlaying: boolean) {
    return await HttpService.post('v6/liveCommand', {
      id: 25,
      name: 'ON_DEMAND_EVENT_PLAY',
      serialNumbers,
      params: { mediaId, ignoreIfPlaying: !reloadIfPlaying }
    });
  }

  async stopEvents(serialNumbers: string[], mediaId: number) {
    return await HttpService.post('v6/liveCommand', {
      id: 26,
      name: 'EVENT_STOP',
      serialNumbers,
      params: { mediaId }
    })
  }
}