// eslint-disable-line import/no-webpack-loader-syntax
import AddressingWorker from "worker-loader!./addressingWorker"; // eslint-disable-line import/no-webpack-loader-syntax
import { AppService } from 'app.service';
import { IdName, normalize } from 'react-tools';
import { IPlayer } from '@models';

const worker: any = new AddressingWorker();

const publish = (message: any) => {
  worker.postMessage(message);
};

const subscribe = (handler: (response: any) => void) => {
  worker.onmessage = handler;
};

const PUBLISH_CHANNEL_MESSAGE = 'request_buckets_init';
const PUBLISH_EVENT_MESSAGE = 'request_apply_addressing_rules';
const SUBSCRIBE_MESSAGE = 'response_apply_addressing_rules';

class SubscriptionService {
  private service = new AppService();

  async publishChannel(channelId: number, workgroupId: number) {
    const players: IPlayer[] = await this.service.getAddressingTree(channelId, workgroupId);
    const allowedPlayers = players.filter(e => e.entityType === 5);

    publish({
      message: PUBLISH_CHANNEL_MESSAGE,
      data: {
        result: players,
        currentWorkgroupID: workgroupId
      }
    });
  }

  async publishEvent(channelId: number, event: IdName) {
    const rules = await this.service.getAddressingRules(channelId, event.id)
    publish({
      message: PUBLISH_EVENT_MESSAGE,
      requestId: +new Date(),
      mediaName: event.name,
      data: {
        denyRules: rules
      }
    });
  }

  subscribe(callback: (players: { [key: number]: number }) => void) {
    subscribe((response: any) => {
      if (response.data.message === SUBSCRIBE_MESSAGE) {
        const streams: { [key: number]: number } = {};
        for (var hashKey in response.data.data.bucketStream) {
          const checkState = response.data.data.bucketStream[hashKey];
          var groups = hashKey.match(/Stream(\d+)/) as any;
          var streamId = groups[1];
          streams[streamId] = checkState;
        }
        return callback(streams);
      }
    })
  }
}

export default new SubscriptionService();