import { useContext } from 'react'
import { AppContext, AppContextData } from './app.context'
import { Channel } from '@models';
import { IdName, denormalize } from 'react-tools';

export const useAppContext = (): AppContextData => {
  return useContext(AppContext);
}

export const useChannels = (): Channel[] => {
  const context = useAppContext();
  return context.channels;
}

export const useLoading = (): boolean => {
  const context = useAppContext()
  return context.loading;
}

export const useChannel = (): Channel | null => {
  const context = useAppContext();
  return context.channel;
}

export const useSetChannel = (): (channel: Channel) => void => {
  const context = useAppContext();

  return (channel: Channel) => {
    context.setChannel(channel);
  }
}

export const useGetSetChannel = (): [Channel | null, (channel: Channel) => void] => {
  const channel = useChannel();
  const setChannel = useSetChannel();
  return [channel, setChannel];
}

export const useEvents = (): IdName[] => {
  const context = useAppContext();
  return context.channel ? context.channel.events : [];
}

export const useEvent = () => {
  const context = useAppContext();
  return context.event;
}

export const useSetEvent = (): (event: IdName) => void => {
  const context = useAppContext();

  return (event: IdName) => {
    context.setEvent(event);
  }
}

export const useGetSetEvent = () => {
  const setEvent = useSetEvent();
  const event = useEvent();
  return { event, setEvent };
}


export const usePlayers = () => {
  const context = useAppContext();

  if (context.channel && context.channel.devices) {
    const allowedDevices = context.channel.devices.filter(d => {
      return d.addressingState === 0 || d.addressingState === 1;
    });
    return allowedDevices.alphabeticalSort('name', 'asc');
  }
  return [];
}

export const useAddressingLoading = () => {
  const context = useAppContext();
  return context.addressingStreamsLoading;
}

export const useLiveCommands = () => {
  const { playEvents, stopEvents } = useAppContext();

  return { playEvents, stopEvents };
}