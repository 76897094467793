import React from 'react';

import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/core';

import { SnackbarProvider } from 'notistack';
import { moodThemeDark } from './themes.jss';
import { AppRoutingSwitch } from './routing/router';
import { AppContextProvider } from './duck';

import "./utils/array";
import { NotifierContainer } from 'react-tools';

const generateClassName = createGenerateClassName({
  productionPrefix: 'oda',
  disableGlobal: true
});

function App() {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={moodThemeDark}>
        <ScopedCssBaseline style={{ height: '100%' }}>
          <SnackbarProvider maxSnack={3}>
            <AppContextProvider>
              <AppRoutingSwitch />
              <NotifierContainer />
            </AppContextProvider>
          </SnackbarProvider>
        </ScopedCssBaseline>
      </ThemeProvider>
    </StylesProvider>
  );
}


export default App;
