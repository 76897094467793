import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useAddressingLoading, useEvent, useLiveCommands } from 'duck';
import { useStyles } from './styles.jss';
import { useSearchDialog, MultipleSelectList, BottomAppBar } from '@shared';
import { IdName, ButtonItem } from 'react-tools';
import { SkeletonPlaceholder } from '@shared/skeleton-list';


import { IPlayer, Device } from '@models';
import { usePlayerViewBarButtons, useFilteredPlayers } from './hooks';
import { PlayerMenuAction, DrawerMenu } from './drawer-menu';
import { useTitle } from 'routing';
import { Switch, Typography, Divider } from '@material-ui/core';
import { TimerDialog } from './timer-dialog';

import Play from '@material-ui/icons/PlayCircleFilled';
import Stop from '@material-ui/icons/Stop';
import { ConfirmDialog } from './confirm-dialog';

const startEventTitle = 'Start On Demand Event?';
const startEventText = 'Regular playback will end and "EVENT_NAME" event will begin.';

const endEventTitle = 'End On Demand Event?';
const endEventText = 'The On Demand event will end and regular playback will resume.';

export const PlayerView = () => {

  const classes = useStyles();

  const event = useEvent();

  useTitle(event ? event.name : 'Select Devices');
  const addressingLoading = useAddressingLoading();

  const [selected, setSelected] = useState<Device[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [timerOpen, setTimerOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [ignoreIfPlaying, setIgnoreIfPlaying] = useState(false);
  const [eventType, setEventType] = useState<'play' | 'stop' | null>();

  const { Dialog, query, searchOpen, toggleSearchDialog } = useSearchDialog('Search devices');

  const { players, allPlayers } = useFilteredPlayers(query);

  const { playEvents, stopEvents } = useLiveCommands();

  usePlayerViewBarButtons(searchOpen, toggleSearchDialog, setMenuOpen);

  useEffect(() => {
    if (allPlayers.length)
      setSelected(allPlayers);
  }, [allPlayers.length]);

  const onEventClicked = useCallback((eventType: 'play' | 'stop') => () => {
    if (selected.length) {
      setEventType(eventType);
      setConfirmOpen(true);
    }
  }, [selected]);

  const bottomButtons = useMemo<ButtonItem[]>(() => [{
    onClick: onEventClicked('stop'),
    icon: () => <Stop />,
    tooltip: 'Stop'
  },
  {
    onClick: onEventClicked('play'),
    icon: () => <Play />,
    tooltip: 'Play Now'
  }], [onEventClicked]);

  const onMenuAction = useCallback((menuOption: PlayerMenuAction) => {
    setMenuOpen(false);
    switch (menuOption) {
      case PlayerMenuAction.SelectAll:
        return setSelected(allPlayers);
      case PlayerMenuAction.UnselectAll:
        return setSelected([]);
      default:
        setSelected(allPlayers.filter(e => selected.findIndex(f => f.id === e.id) === -1));
    }
  }, [allPlayers, selected]);

  const onSelect = useCallback((players: IdName[]) => setSelected(players as Device[]), []);
  const toggleMenu = useCallback(() => setMenuOpen(prev => !prev), []);

  const label = useCallback((p: IdName) => {
    return `${(p as Device).site.name} - ${(p as Device).name}`;
  }, []);

  const handleIgnoreIfPlayingSwitch = useCallback((event: any, checked: boolean) => {
    setIgnoreIfPlaying(checked)
  }, [])

  const handleConfirm = useCallback(() => {
    const serials = selected.map(e => e.serialNumber);
    if (serials.length) {
      if (eventType === 'play') {
        playEvents(serials, ignoreIfPlaying);
      } else {
        stopEvents(serials);
      }
    }
    setTimeout(() => setTimerOpen(false), 0);
  }, [selected, ignoreIfPlaying, eventType]);

  const { confirmText, confirmTitle, confirmLabel } = useMemo(
    () => ({
      confirmText: eventType === 'play' ? startEventText.replace('EVENT_NAME', event ? event.name : '') : endEventText,
      confirmTitle: eventType === 'play' ? startEventTitle : endEventTitle,
      confirmLabel: eventType === 'play' ? 'START' : 'END'
    }),
    [eventType]
  );

  const onConfirm = useCallback(() => {
    setTimerOpen(true);
    setConfirmOpen(false);
  }, []);

  const onConfirmCancel = useCallback(() => setConfirmOpen(false), []);

  const noItemsFoundMessage = useMemo(
    () => allPlayers.length === 0 ? `No devices available for ${event?.name}` : (players.length ? 'No devices found' : ''),
    [allPlayers.length, allPlayers.length, event]
  );

  const noItemsFound = useMemo(() => players.length === 0, [players]);

  return addressingLoading ? <SkeletonPlaceholder /> : (
    <div className={classes.root}>
      {Dialog}

      <div className={classes.switchContainer}>
        <Typography color={'textPrimary'}>
          Reload if playing
        </Typography>
        <Switch name="checkedA" color={'secondary'} onChange={handleIgnoreIfPlayingSwitch} />
      </div>

      <Divider />

      {noItemsFound ? (
        <div className={classes.noItems}>
          <Typography align={'center'} color={'textPrimary'}>{noItemsFoundMessage}</Typography>
        </div>
      ) : (
          <div className={classes.listContainer}>
            <MultipleSelectList
              items={players as IdName[]}
              label={label}
              onSelect={onSelect}
              defaultSelection={selected}
            />
            <TimerDialog open={timerOpen} confirm={handleConfirm} onClose={() => setTimerOpen(false)} />

            <ConfirmDialog
              open={confirmOpen}
              confirm={onConfirm}
              cancel={onConfirmCancel}
              cancelLabel={'Cancel'}
              confirmLabel={confirmLabel}
              text={confirmText}
              title={confirmTitle}
            />
          </div>
        )
      }

      <BottomAppBar buttons={bottomButtons} />

      <DrawerMenu
        open={menuOpen}
        toggle={toggleMenu}
        onClick={onMenuAction}
      />
    </div >
  )
}
