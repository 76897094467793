import React from 'react';
import { makeStyles, createStyles, Theme, AppBar, Toolbar, IconButton, Typography, Button } from '@material-ui/core';
import { ButtonItem } from 'react-tools';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      top: 'auto',
      bottom: 0
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    barButtonRoot: {
      display: 'flex'
    },
    rightBarButton: {
      marginRight: theme.spacing(1)
    }
  }),
);

interface BottomAppBarProps {
  buttons: ButtonItem[];
}

const BarButton = ({ button, index }: { button: ButtonItem, index: number }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.barButtonRoot}
      variant={'text'}
      onClick={button.onClick}
      disabled={button.disabled}
    >
      {index > 0 && <Typography className={classes.rightBarButton}>{button.tooltip}</Typography>}
      {button.icon && button.icon()}
      {index === 0 && <Typography>{button.tooltip}</Typography>}
    </Button >
  );
}

export const BottomAppBar = ({ buttons }: BottomAppBarProps) => {
  const classes = useStyles();

  return (
    <AppBar position={'relative'} className={classes.root} color={'secondary'}>
      <Toolbar className={classes.toolbar}>
        {buttons.length === 1 ? (
          <>
            <div></div>
            {buttons.map((button, index) => <BarButton button={button} key={index} index={index + 1} />)}
          </>
        ) : buttons.map((button, index) => <BarButton button={button} key={index} index={index} />)
        }
      </Toolbar>
    </AppBar>
  )
}