import React, { useCallback } from 'react';

import SelectAll from '@material-ui/icons/SelectAll';
import DeselectAll from '@material-ui/icons/TabUnselected';
import InvertSelection from '@material-ui/icons/FlipToBack';

import { Drawer, ListItemIcon, ListItem, ListItemText, List } from '@material-ui/core';

export enum PlayerMenuAction {
  SelectAll = 'SELECT_ALL',
  UnselectAll = 'UNSELECT_ALL',
  InvertSelection = 'INVERT_SELECTION'
}

interface DrawerMenuProps {
  open: boolean;
  toggle: () => void;
  onClick: (action: PlayerMenuAction) => void;
}

export const DrawerMenu = ({ open, toggle, onClick }: DrawerMenuProps) => {

  const handleClick = useCallback((menuItem: PlayerMenuAction) => () => onClick(menuItem), [onClick]);

  return (
    <Drawer anchor={'bottom'} open={open} onClose={toggle}>
      <List component='nav'>
        <ListItem onClick={handleClick(PlayerMenuAction.SelectAll)}>
          <ListItemIcon>
            <SelectAll />
          </ListItemIcon>
          <ListItemText id="switch-list-label-wifi" primary="Select All" />
        </ListItem>

        <ListItem onClick={handleClick(PlayerMenuAction.UnselectAll)}>
          <ListItemIcon>
            <DeselectAll />
          </ListItemIcon>
          <ListItemText id="switch-list-label-wifi" primary="Deselect All" />
        </ListItem>

        <ListItem onClick={handleClick(PlayerMenuAction.InvertSelection)}>
          <ListItemIcon>
            <InvertSelection />
          </ListItemIcon>
          <ListItemText id="switch-list-label-wifi" primary="Invert Selection" />
        </ListItem>
      </List>
    </Drawer>
  )
}